import { useNavigate } from "react-router-dom";
function Success() {
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const notificationId: string = queryParameters.get("id") ?? "";
  return (
    <div>
      <div className="h-screen flex items-center justify-center">
        <div className="font-latoRegular">
          <p className="text-xl lg:text-4xl font-bold  text-center text-green-500">
            Success
          </p>
          <p className="text-md text-black-500 text-center">
            Your answer has been submitted successfully. <br />
            You can close your browser tab now.
          </p>

          <div className="text-center">
            <button
              type="submit"
              className="bg-slate-800 font-bold text-sm text-white py-3 mt-6 rounded-lg px-6 "
              onClick={() => {
                navigate(`/answer?id=${notificationId}`);
              }}
            >
              Go Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Success;
