import React from "react";
import alertAwareLogo from "../../../src/alertaware_logo_large.png";

interface NotFoundProps {}

function NotFound() {
  return (
    <div>
      <div className="h-screen flex items-center justify-center">
        <div className="font-latoRegular">
          <p className=" text-3xl lg:text-4xl font-bold  text-center">404</p>
          <p className="text-l lg:3xl text-black-500 font-bold text-center">
            The page you are looking for is not found
          </p>
        </div>
      </div>
    </div>
  );
}
export default NotFound;
