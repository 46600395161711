import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import NotFound from "./components/NotFound/NotFound";
import PollDisplayer from "./components/PollDisplayer/PollDisplayer";
import Success from "./components/Success/Success";

function App() {
  return (
    <div>
      <Router>
        <div>
          <Routes>
            <Route path="/answer" element={<PollDisplayer />} />
            <Route path="/not-found" element={<NotFound />} />
            <Route path="/success" element={<Success />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
